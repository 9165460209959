import React from 'react';

export const InfoIconFull = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM8.46703 6.9443C7.97947 7.39595 7.48782 7.85139 7.47985 8.83055H6.348C6.348 7.39742 6.95591 6.8497 7.47861 6.37875C7.8728 6.02359 8.21853 5.7121 8.21853 5.09744C8.21853 4.38259 7.78962 3.96957 7.11845 3.96957C6.55054 3.96957 6.00249 4.30713 5.99455 5.14907H4.7515C4.75547 3.70745 5.79201 2.94892 7.11845 2.94892C8.57595 2.94892 9.4298 3.80674 9.4298 5.10538C9.4298 6.05245 8.95043 6.49651 8.46703 6.9443ZM7.7499 10.4866C7.7499 10.9473 7.38453 11.3007 6.92385 11.3007C6.44331 11.3007 6.08986 10.9473 6.08986 10.4866C6.08986 10.0259 6.44331 9.66454 6.92385 9.66454C7.38453 9.66454 7.7499 10.0259 7.7499 10.4866Z"
                fill="#C4C4C4"
            />
        </svg>
    );
};
