import React from 'react';

export const CloseIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4501 10.4491C10.7538 10.1454 10.7538 9.65294 10.4501 9.34922L6.60062 5.50008L10.45 1.65105C10.7537 1.34733 10.7537 0.854888 10.45 0.55116C10.1462 0.247433 9.65374 0.247432 9.34999 0.55116L5.50063 4.40019L1.65018 0.550061C1.34642 0.246334 0.853944 0.246334 0.55019 0.550061C0.246437 0.853789 0.246437 1.34623 0.55019 1.64996L4.40064 5.50008L0.550081 9.35031C0.246327 9.65404 0.246327 10.1465 0.550081 10.4502C0.853834 10.7539 1.34632 10.7539 1.65007 10.4502L5.50063 6.59997L9.3501 10.4491C9.65385 10.7528 10.1463 10.7528 10.4501 10.4491Z"
                fill="#C4C4C4"
            />
        </svg>
    );
};
