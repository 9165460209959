import React from 'react';

export const SortIcon = () => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.6842 8C17.6842 8.4 17.3817 8.70588 16.9862 8.70588H6.16621C5.88698 9.52941 5.09585 10.1176 4.18837 10.1176C3.28089 10.1176 2.51302 9.52941 2.21053 8.70588H0.698061C0.302493 8.70588 0 8.4 0 8C0 7.6 0.302493 7.29412 0.698061 7.29412H2.21053C2.48975 6.47059 3.28089 5.88235 4.18837 5.88235C5.09585 5.88235 5.86371 6.47059 6.16621 7.29412H16.9862C17.3817 7.29412 17.6842 7.6 17.6842 8ZM16.9862 13.1765H10.8199C10.5407 12.3529 9.74959 11.7647 8.84211 11.7647C7.93463 11.7647 7.16676 12.3529 6.86427 13.1765H0.698061C0.302493 13.1765 0 13.4824 0 13.8824C0 14.2824 0.302493 14.5882 0.698061 14.5882H6.86427C7.14349 15.4118 7.93463 16 8.84211 16C9.74959 16 10.5175 15.4118 10.8199 14.5882H16.9862C17.3817 14.5882 17.6842 14.2824 17.6842 13.8824C17.6842 13.4824 17.3817 13.1765 16.9862 13.1765ZM0.698061 2.82353H11.518C11.7972 3.64706 12.5884 4.23529 13.4958 4.23529C14.4033 4.23529 15.1712 3.64706 15.4737 2.82353H16.9862C17.3817 2.82353 17.6842 2.51765 17.6842 2.11765C17.6842 1.71765 17.3817 1.41176 16.9862 1.41176H15.4737C15.1945 0.588235 14.4033 0 13.4958 0C12.5884 0 11.8205 0.588235 11.518 1.41176H0.698061C0.302493 1.41176 0 1.71765 0 2.11765C0 2.51765 0.302493 2.82353 0.698061 2.82353Z"
            fill="#808080"
        />
    </svg>
);
