import React from 'react';

export const ArrowUpDownIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.5 0.5C4.5 0.223858 4.27614 -1.20706e-08 4 0C3.72386 1.20706e-08 3.5 0.223858 3.5 0.5L4.5 0.5ZM3.64645 15.8536C3.84171 16.0488 4.15829 16.0488 4.35355 15.8536L7.53553 12.6716C7.7308 12.4763 7.7308 12.1597 7.53553 11.9645C7.34027 11.7692 7.02369 11.7692 6.82843 11.9645L4 14.7929L1.17157 11.9645C0.976311 11.7692 0.659729 11.7692 0.464467 11.9645C0.269204 12.1597 0.269204 12.4763 0.464467 12.6716L3.64645 15.8536ZM3.5 0.5L3.5 15.5L4.5 15.5L4.5 0.5L3.5 0.5Z"
                fill="#474747"
            />
            <path
                d="M10.5 15.5C10.5 15.7761 10.7239 16 11 16C11.2761 16 11.5 15.7761 11.5 15.5L10.5 15.5ZM11.3536 0.146446C11.1583 -0.0488157 10.8417 -0.0488157 10.6464 0.146446L7.46447 3.32843C7.2692 3.52369 7.2692 3.84027 7.46447 4.03553C7.65973 4.2308 7.97631 4.2308 8.17157 4.03553L11 1.20711L13.8284 4.03553C14.0237 4.2308 14.3403 4.2308 14.5355 4.03553C14.7308 3.84027 14.7308 3.52369 14.5355 3.32843L11.3536 0.146446ZM11.5 15.5L11.5 0.5L10.5 0.5L10.5 15.5L11.5 15.5Z"
                fill="#474747"
            />
        </svg>
    );
};
