import React from 'react';

export const FilterIcon = () => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.6842 7.00034C15.6842 7.40034 15.3817 7.70623 14.9862 7.70623C8.76764 7.70623 7.56963 7.70623 2.69806 7.70623C2.30249 7.70623 2 7.40034 2 7.00034C2 6.60034 2.30249 6.29446 2.69806 6.29446C7.55672 6.29446 8.7745 6.29446 14.9862 6.29446C15.3817 6.29446 15.6842 6.60034 15.6842 7.00034ZM11.9862 12.1768C6.58355 12.1768 12.1054 12.1768 5.69806 12.1768C5.30249 12.1768 5 12.4827 5 12.8827C5 13.2827 5.30249 13.5886 5.69806 13.5886C12.1007 13.5886 6.57881 13.5886 11.9862 13.5886C12.3817 13.5886 12.6842 13.2827 12.6842 12.8827C12.6842 12.4827 12.3817 12.1768 11.9862 12.1768ZM0.698061 1.82387C6.2087 1.82387 11.7976 1.82387 16.9862 1.82387C17.3817 1.82387 17.6842 1.51799 17.6842 1.11799C17.6842 0.717992 17.3817 0.412109 16.9862 0.412109C11.5568 0.412109 6.12742 0.412109 0.698061 0.412109C0.302493 0.412109 0 0.717992 0 1.11799C0 1.51799 0.302493 1.82387 0.698061 1.82387Z"
            fill="#808080"
        />
    </svg>
);
