import React from 'react';

export const WarningSmallIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.2159 13.5295L8.88189 1.6545C8.50589 0.9485 7.49389 0.9485 7.11689 1.6545L0.783885 13.5295C0.428885 14.1955 0.911885 15.0005 1.66689 15.0005H14.3339C15.0879 15.0005 15.5709 14.1955 15.2159 13.5295ZM7.99989 13.0005C7.44789 13.0005 6.99989 12.5525 6.99989 12.0005C6.99989 11.4485 7.44789 11.0005 7.99989 11.0005C8.55189 11.0005 8.99989 11.4485 8.99989 12.0005C8.99989 12.5525 8.55189 13.0005 7.99989 13.0005ZM8.99989 10.0005H6.99989V6.0005H8.99989V10.0005Z"
            fill="white"
        />
    </svg>
);
